// ======================
// MigrateOpsDetailsView
// ======================

import { ScreenContainer } from "../../layout/ScreenCommon";
import { BackButton } from "../../../common/CommonButtons";
import { getOperationRecipeLabel, MigrateOpsStatusIcon, useNavigateToOpsList } from "../MigrateOpsCommon";
import { useCancelOperation, useGetMigrateOpDetails, useRetryOperation } from "../migrate_ops_hooks";
import { useParams } from "react-router-dom";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { Box, Button, Link, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { MdRefresh } from "react-icons/md";
import { convertTimestampObjectToDate, formatKnownDataType, formatProtoDurationObject, KnownDataType } from "../../../common/utils/formatter";
import * as React from "react";
import { TabConfig, TabGroup } from "../../../common/tabs/TabComponents";
import { OperationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/operation_status_pb";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { MigrateOpsTasksTab } from "./MigrateOpsTasksTab";
import { useNavigateToDeploymentDetails } from "../../galaxymigrate/GalaxyMigrateCommon";
import { MigrateOpsMigrationSessionTab } from "./MigrateOpsMigrationSessionsTab";
import { MigrateOpsConfigurationsTab } from "./MigrateOpsConfigurationsTab";
import { OperationDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/operation_pb";
import { getUserFullNameFromObject } from "../../settings/ProjectUsers";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { useIsDesktop } from "../../layout/MainLayout";

interface MigrateOpsDetailsViewProps {}

export const MigrateOpsDetailsView: React.FC<MigrateOpsDetailsViewProps> = (p) => {
    const goToOperationsList = useNavigateToOpsList();
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { opId } = useParams();
    const [queryInterval, setQueryInterval] = React.useState<number>(60 * 1000);
    const queryResult = useGetMigrateOpDetails(Number(opId), queryInterval);
    const tabConfigs: TabConfig[] = [
        {
            label: "Operation Tasks",
            renderer: () => <MigrateOpsTasksTab data={queryResult.data?.details} setQueryInterval={setQueryInterval} loggingQueryInterval={queryInterval} />,
        },
        {
            label: "Configuration",
            renderer: () => <MigrateOpsConfigurationsTab data={queryResult.data?.details} />,
        },
        {
            label: "Migration Sessions",
            renderer: () => <MigrateOpsMigrationSessionTab opId={Number(opId)} />,
        },
    ];
    return (
        <ScreenContainer sx={{ paddingBottom: 0 }}>
            <QueryResultWrapper queryResult={queryResult}>
                <Box
                    sx={{
                        position: "sticky",
                        top: isDesktop ? "70px" : "64px",
                        backgroundColor: theme.palette.cirrus.main,
                        zIndex: theme.zIndex.modal,
                    }}
                >
                    <BackButton navFunction={goToOperationsList} label={"Back To Operations"} />
                    <OperationDetailsBasicInfo data={queryResult.data?.details} />
                </Box>
                <TabGroup configs={tabConfigs} border />
            </QueryResultWrapper>
        </ScreenContainer>
    );
};

// ======================
// OperationDetailsBasicInfo
// ======================

interface OperationDetailsBasicInfoProps {
    data: OperationDetails.AsObject;
}

const OperationDetailsBasicInfo: React.FC<OperationDetailsBasicInfoProps> = (p) => {
    const { data } = p;
    const config = data.configuration;
    const opInfo = data.info;
    const retryOperation = useRetryOperation();
    const goToDeploymentDetails = useNavigateToDeploymentDetails();
    const cancelableOperations = [
        OperationStatus.OperationStatus.WAITING,
        OperationStatus.OperationStatus.PENDING,
        OperationStatus.OperationStatus.RUNNING,
        OperationStatus.OperationStatus.AWAITING_USER_INPUT,
    ];
    const cancelOperation = useCancelOperation();
    const globalDialogState = useGlobalDialogState();
    const handleCancelOperation = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            title: "Cancel Operation",
            message: "Are you sure you want to cancel this operation?",
            okButtonLabel: "Cancel Operation",
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            cancelOperation.mutate(opInfo.id);
        }
    };

    return (
        <Box>
            <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Box pt={1}>
                        <MigrateOpsStatusIcon status={opInfo.status} size={72} tooltip />
                    </Box>
                    <Box>
                        <Typography variant={"h4"} fontWeight={600}>
                            {opInfo.name}
                        </Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {`#${opInfo.id}`}
                            </Typography>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {`|`}
                            </Typography>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {getOperationRecipeLabel(opInfo.recipe.id)}
                            </Typography>
                            {!!opInfo.deployment && (
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {` | `}
                                    </Typography>
                                    <Link variant={"subtitle2"} onClick={() => goToDeploymentDetails(opInfo.deployment?.systemId)}>
                                        {opInfo.deployment?.systemName}
                                    </Link>
                                </Stack>
                            )}
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Tooltip title={`Created ${formatKnownDataType(convertTimestampObjectToDate(opInfo.createdAt), KnownDataType.DATE)}`}>
                                <Typography variant={"subtitle2"} color={"textSecondary"}>
                                    {opInfo.createdAt
                                        ? `Created ${formatKnownDataType(convertTimestampObjectToDate(opInfo.createdAt), KnownDataType.DATE_RELATIVE)}${
                                              opInfo.createdByUser ? ` by ${getUserFullNameFromObject(opInfo.createdByUser)}` : ""
                                          }`
                                        : ""}
                                </Typography>
                            </Tooltip>
                            {opInfo.startedAt && (
                                <>
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {` | `}
                                    </Typography>
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {`Time Elapsed: ${formatProtoDurationObject(opInfo.timeElapsed)}`}
                                    </Typography>
                                </>
                            )}
                            {opInfo.startedAt && (
                                <>
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {` | `}
                                    </Typography>
                                    <Tooltip title={`Started ${formatKnownDataType(convertTimestampObjectToDate(opInfo.startedAt), KnownDataType.DATE)}`}>
                                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                                            {`Started ${formatKnownDataType(convertTimestampObjectToDate(opInfo.startedAt), KnownDataType.DATE_RELATIVE)}`}
                                        </Typography>
                                    </Tooltip>
                                </>
                            )}
                            {config.scheduledStartTime && !opInfo.startedAt && (
                                <>
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {` | `}
                                    </Typography>
                                    <Tooltip
                                        title={`Scheduled to start ${formatKnownDataType(
                                            convertTimestampObjectToDate(config.scheduledStartTime),
                                            KnownDataType.DATE
                                        )}`}
                                    >
                                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                                            {`Scheduled to start ${formatKnownDataType(
                                                convertTimestampObjectToDate(config.scheduledStartTime),
                                                KnownDataType.DATE_RELATIVE
                                            )}`}
                                        </Typography>
                                    </Tooltip>
                                </>
                            )}
                            {opInfo.ended && (
                                <>
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {` | `}
                                    </Typography>
                                    <Tooltip title={`Ended ${formatKnownDataType(convertTimestampObjectToDate(opInfo.endedAt), KnownDataType.DATE)}`}>
                                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                                            {`Ended ${formatKnownDataType(convertTimestampObjectToDate(opInfo.endedAt), KnownDataType.DATE_RELATIVE)}`}
                                        </Typography>
                                    </Tooltip>
                                </>
                            )}
                        </Stack>
                        <Box>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {"Notes: "}
                                {opInfo.notes}
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
                {opInfo.status === OperationStatus.OperationStatus.FAILED && (
                    <OperatorView>
                        <Button startIcon={<MdRefresh />} onClick={() => retryOperation.mutate(opInfo.id)} variant={"contained"} color={"error"}>
                            {"Retry Operation"}
                        </Button>
                    </OperatorView>
                )}
                {cancelableOperations.includes(opInfo.status) && (
                    <OperatorView>
                        <Button onClick={handleCancelOperation} variant={"contained"} color={"error"}>
                            {"Cancel Operation"}
                        </Button>
                    </OperatorView>
                )}
            </Stack>
        </Box>
    );
};
