// ======================
// MigrateOpsListView
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { MigrateOpsNewOperationButton } from "./MigrateOpsNewOperationPage";
import { useListMigrateOps } from "./migrate_ops_hooks";
import * as React from "react";
import { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { QueryListTable } from "../../common/table/QueryListTable";
import { OperationListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/operation_pb";
import { Box, Button, Card, CardContent, Chip, Grid, Link, Stack, Tooltip, Typography } from "@mui/material";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { MdListAlt, MdOutlineTimer } from "react-icons/md";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getMigrateOpStatusLabel, getOperationRecipeLabel, MigrateOpsStatusIcon, MigrateOpStatusChip, useNavigateToOpDetails } from "./MigrateOpsCommon";
import { DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import { useNavigateToDeploymentDetails } from "../galaxymigrate/GalaxyMigrateCommon";
import { HiOutlineServer } from "react-icons/hi";
import { IoDocumentText } from "react-icons/io5";
import { getUserFullNameFromObject } from "../settings/ProjectUsers";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";
import EmptyTableIcon from "../../assets/empty_states/empty-migrateops.svg";

interface MigrateOpsListViewProps {}

export const MigrateOpsListView: React.FC<MigrateOpsListViewProps> = (p) => {
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const projectId = useCurrentProjectID();

    const queryResult = useListMigrateOps(projectId, pageIndex, pageSize);

    const actions =
        queryResult.data?.pagerMeta?.totalItems > 0 ? (
            <Stack direction={"row"} spacing={1}>
                <MigrateOpsNewOperationButton />
            </Stack>
        ) : null;

    return (
        <>
            <ScreenContainer>
                <ScreenTitleBar title={"Migration Operations"} actions={actions} />
                <Box pb={2}>
                    <IntroducingMigrateOpsBanner />
                </Box>
                <QueryListTable
                    listComponent={MigrateOpsListCard}
                    data={queryResult.data?.itemsList}
                    pagination={{ pageIndex, pageSize }}
                    pageCount={queryResult.data?.pagerMeta.totalPages}
                    setPagination={setPagination}
                    error={queryResult.error}
                    isError={queryResult.isError}
                    isLoading={queryResult.isLoading}
                    emptyTableConfig={{
                        iconPath: EmptyTableIcon,
                        title: "Enterprise-Scale Automation with MigrateOps",
                        message:
                            "Automate your enterprise-scale data mobility workflows today with MigrateOps, Cirrus Data Cloud's Data Mobility as Code automation platform.",
                        actionButton: (
                            <Stack direction={"column"} spacing={2} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                                <MigrateOpsNewOperationButton />
                            </Stack>
                        ),
                    }}
                />
            </ScreenContainer>
        </>
    );
};

// ======================
// MigrateOpsListCard
// ======================

interface MigrateOpsListCardProps {
    data: OperationListItem.AsObject;
}

export const MigrateOpsListCard: React.FC<MigrateOpsListCardProps> = (p) => {
    const { data } = p;
    const opInfo = data.info;
    const steps = data.stepsList;
    const currentStepKind = steps.find((s) => s.stepNumber === opInfo.currentStep)?.kind;
    const currentStepDescriptor = opInfo.recipe.tasksList.find((t) => t.kind === currentStepKind);
    const goToOpDetails = useNavigateToOpDetails();
    const goToDeploymentDetails = useNavigateToDeploymentDetails();
    return (
        <Card sx={{ marginBottom: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <CardContent>
                        <Stack spacing={2}>
                            <Stack spacing={1}>
                                <Box>
                                    <MigrateOpStatusChip status={opInfo.status} />
                                </Box>
                                <Link onClick={() => goToOpDetails(opInfo.id)} variant={"h5"} fontWeight={600}>
                                    {opInfo.name || `Operation #${opInfo.id}`}
                                </Link>
                            </Stack>
                            <Stack>
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    <IoDocumentText />
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {"#"}
                                        {opInfo.id}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    <MdListAlt size={18} />
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {`${getOperationRecipeLabel(opInfo.recipe.id)}`}
                                    </Typography>
                                </Stack>
                                <Tooltip title={formatKnownDataType(convertTimestampObjectToDate(opInfo.createdAt), KnownDataType.DATE)}>
                                    <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                        <MdOutlineTimer size={18} />
                                        <Box>
                                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                                {opInfo.createdAt
                                                    ? `Created ${formatKnownDataType(
                                                          convertTimestampObjectToDate(opInfo.createdAt),
                                                          KnownDataType.DATE_RELATIVE
                                                      )}${opInfo.createdByUser ? ` by ${getUserFullNameFromObject(opInfo.createdByUser)}` : ""}`
                                                    : ""}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Tooltip>
                                {!!opInfo.deployment && (
                                    <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                        <HiOutlineServer size={18} />
                                        <Box>
                                            <Link onClick={() => goToDeploymentDetails(opInfo.deployment?.systemId)}>{opInfo.deployment?.systemName}</Link>
                                        </Box>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </CardContent>
                </Grid>
                <Grid item xs={12} md={9}>
                    <CardContent>
                        <Box pt={1}>
                            <DarkFlatOutlinedCard>
                                <CardContent>
                                    <Box>
                                        <Grid container mb={2}>
                                            {steps.map((s, i) => {
                                                const stepRecipe = opInfo.recipe.tasksList.find((t) => t.kind === s.kind);
                                                return (
                                                    <Grid item key={s.kind}>
                                                        <Stack direction={"row"} alignItems={"center"}>
                                                            <Tooltip
                                                                arrow
                                                                key={s.kind}
                                                                title={
                                                                    <Box>
                                                                        <Typography fontWeight={600}>{stepRecipe?.name}</Typography>
                                                                        <Typography>
                                                                            {"Status: "}
                                                                            {getMigrateOpStatusLabel(s.status)}
                                                                        </Typography>
                                                                        <Typography>
                                                                            {"Run #"}
                                                                            {s.attempt}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            >
                                                                <Box>
                                                                    <MigrateOpsStatusIcon status={s.status} size={40} />
                                                                </Box>
                                                            </Tooltip>
                                                            {i !== steps.length - 1 && (
                                                                <Box width={15} height={"1px"} sx={{ background: "rgba(255,255,255,.3)" }} />
                                                            )}
                                                        </Stack>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography color={"textSecondary"}>{`(${opInfo.currentStep}/${steps.length})`}</Typography>
                                            <Typography>{currentStepDescriptor?.description}</Typography>
                                        </Stack>
                                        <Box display={"flex"}>
                                            <Typography color={"textSecondary"}>{`Last Updated:`}&nbsp;</Typography>
                                            <Typography>
                                                {formatKnownDataType(
                                                    convertTimestampObjectToDate(opInfo.lastPingAt ?? opInfo.endedAt ?? opInfo.startedAt ?? opInfo.createdAt),
                                                    KnownDataType.DATE_RELATIVE
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </DarkFlatOutlinedCard>
                        </Box>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

// ======================
// NewFeatureBanner
// ======================

interface IntroducingMigrateOpsBannerProps {}

const IntroducingMigrateOpsBanner: React.FC<IntroducingMigrateOpsBannerProps> = (p) => {
    const openHelpArticle = useOpenHelpArticle();

    return (
        <Card
            sx={{
                background: `linear-gradient(90deg, #9D52F9 0%, #4286DE 100%)`,
            }}
        >
            <CardContent>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Box>
                            <Typography variant={"h5"}>{`🎉`}</Typography>
                        </Box>
                        <Box>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Typography variant={"h5"} fontWeight={600}>
                                    {"Introducing MigrateOps"}
                                </Typography>
                                <Chip size={"small"} color={"primary"} label={"NEW"} />
                            </Stack>
                            <Typography>
                                {`Automate your enterprise-scale migration workflows today with MigrateOps, Cirrus Data's Data Mobility as Code automation platform.`}
                            </Typography>
                        </Box>
                    </Stack>
                    <Box>
                        <Button variant={"outlined"} color={"neutral"} onClick={() => openHelpArticle(KnownArticle.MIGRATEOPS)}>
                            {"Learn More"}
                        </Button>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
};
