import { useAppServices } from "../app/services";
import {
    AddProjectIntegration,
    GetAllProjectIntegrations,
    GetDefaultVolumeParams,
    GetEnabledIntegration,
    IntegrationParams,
    IsCapabilitySupported,
    ListIntegrationModules,
    RemoveProjectIntegration,
    TestProjectIntegration,
    UpdateProjectIntegration,
} from "gc-web-proto/galaxycompletepb/apipb/integration_api_pb";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { IntegrationDefinition, IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import Capability = IntegrationDefinition.CapabilityDefinition.Capability;
import { IntegrationMutationKeys, IntegrationQueryKeys } from "../../common/QueryKeys";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";

export const useListProjectIntegrations = () => {
    const apis = useGrpcApiStore();
    const projectId = useCurrentProjectID();
    const req = new GetAllProjectIntegrations.Request().setProjectId(projectId);
    const queryFn = async () => {
        const res = await apis.integrationService.getAllProjectIntegrations(req, null);
        const resObject = res.getItemsList().map((i) => {
            return {
                ...i.toObject(),
            };
        });
        return res.toObject();
    };

    return useQuery({
        queryKey: [IntegrationQueryKeys.GetAllProjectIntegrations, projectId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetEnabledIntegrationDetails = (integrationId: number) => {
    const apis = useGrpcApiStore();
    const req = new GetEnabledIntegration.Request().setId(integrationId);
    const queryFn = async () => {
        const res = await apis.integrationService.getEnabledIntegration(req, null);

        return res.toObject();
    };

    return useQuery({
        queryKey: [IntegrationQueryKeys.GetEnabledIntegrationDetails, integrationId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
        enabled: !!integrationId,
    });
};

export const useListIntegrationModules = () => {
    const apis = useGrpcApiStore();
    const req = new ListIntegrationModules.Request();
    const queryFn = async () => {
        const res = await apis.integrationService.listIntegrationModules(req, null);
        return res.toObject();
    };
    return useQuery({
        queryKey: [IntegrationQueryKeys.ListIntegrationModules],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetDefaultVolumeParams = (integrationId: number) => {
    const apis = useGrpcApiStore();
    const req = new GetDefaultVolumeParams.Request().setId(integrationId);
    const queryFn = async () => {
        const res = await apis.integrationService.getDefaultVolumeParams(req, null);
        return res.toObject();
    };
    return useQuery({
        queryKey: [IntegrationQueryKeys.GetDefaultVolumeParams, integrationId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
        enabled: !!integrationId,
    });
};

export const useIsCapabilitySupported = (module: IntegrationModule, deploymentId: string, capability: Capability) => {
    const apis = useGrpcApiStore();
    const req = new IsCapabilitySupported.Request().setCapability(capability).setDeploymentId(deploymentId).setModule(module);
    const queryFn = async () => await apis.integrationService.isCapabilitySupported(req, null);
    return useQuery({
        queryKey: [IntegrationQueryKeys.IsCapabilitySupported, module, deploymentId, capability],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
        enabled: !!module && !!deploymentId,
    });
};

export const useUpdateProjectIntegration = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    return useMutationTrackAndAlertError({
        mutationKey: [IntegrationMutationKeys.UpdateProjectIntegration],
        mutationFn: async (args: {
            friendlyName: string;
            statusId: string;
            deploymentId: string;
            connParams?: IntegrationParams.ConnParams;
            verboseLogging?: boolean;
            id: number;
        }) => {
            const req = new UpdateProjectIntegration.Request()
                .setId(args.id)
                .setFriendlyName(args.friendlyName)
                .setStatusId(args.statusId)
                .setVerboseLogging(args.verboseLogging);

            if (!!args.connParams && !!args.deploymentId) {
                req.setConnParams(args.connParams);
                req.setDeploymentIdForValidation(args.deploymentId);
            }
            const res = await apis.integrationService.updateProjectIntegration(req, null);
            return res.toObject();
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.refetchQueries({
                queryKey: [IntegrationQueryKeys.GetEnabledIntegrationDetails, variables.id],
            });
        },
    });
};

export const useAddProjectIntegration = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const projectId = useCurrentProjectID();

    return useMutationTrackAndAlertError({
        mutationKey: [IntegrationMutationKeys.AddProjectIntegration],
        mutationFn: async (args: {
            module: IntegrationModule;
            friendlyName: string;
            connParams: IntegrationParams.ConnParams;
            verboseLogging?: boolean;
            deploymentId: string;
            statusId: string;
        }) => {
            const req = new AddProjectIntegration.Request()
                .setProjectId(projectId)
                .setModule(args.module)
                .setFriendlyName(args.friendlyName)
                .setVerboseLogging(args.verboseLogging)
                .setDeploymentIdForValidation(args.deploymentId)
                .setStatusId(args.statusId)
                .setConnParams(args.connParams);

            const res = await apis.integrationService.addProjectIntegration(req, null);
            return res.toObject();
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.refetchQueries({
                queryKey: [IntegrationQueryKeys.GetAllProjectIntegrations, projectId],
            });
        },
    });
};

export const useRemoveProjectIntegration = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const projectId = useCurrentProjectID();
    return useMutationTrackAndAlertError({
        mutationKey: [IntegrationMutationKeys.RemoveProjectIntegration],
        mutationFn: async (id: number) => {
            const req = new RemoveProjectIntegration.Request().setId(id);
            const res = await apis.integrationService.removeProjectIntegration(req, null);
            return res.toObject();
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.refetchQueries({
                queryKey: [IntegrationQueryKeys.GetAllProjectIntegrations, projectId],
            });
        },
    });
};

export const useTestProjectIntegration = () => {
    const apis = useGrpcApiStore();
    return useMutationTrackAndAlertError(
        {
            mutationKey: [IntegrationMutationKeys.TestProjectIntegration],
            mutationFn: async (args: { id: number; statusId: string; deploymentId: string }) => {
                const req = new TestProjectIntegration.Request().setId(args.id).setStatusId(args.statusId).setDeploymentId(args.deploymentId);
                const res = await apis.integrationService.testProjectIntegration(req, null);
                return res.toObject();
            },
        },
        "Checking Integration Connection..."
    );
};
